import React from 'react'
import { columns } from './static'
import { VStack } from '@chakra-ui/react'
import useFetch from '../../hook/useFetch'
import { useNavigate } from 'react-router-dom'
import { getWaMessages } from '../../apis/whatsapp'
import DataTable from '../../commons/DataTable/DataTable'
import CompHeader from '../../commons/CompHeader/CompHeader'

const WhatsappConversations = () => {
    const navigate = useNavigate()
    const { data, isLoading } = useFetch({
        service: getWaMessages,
        pagination: true
    })

    return (
        <VStack alignItems={"flex-start"} w="100%" >
            <CompHeader title="Whatsapp Conversations" />
            <DataTable tableData={{ columns: columns(navigate), data, pagination: true, isLoading }} />
        </VStack>
    )
}

export default WhatsappConversations