import React from 'react'
import CouponForm from './CouponForm'
import { useForm } from 'react-hook-form'
import useSubmit from '../../hook/useSubmit'
import { useLocation, useNavigate } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { Button, HStack, Spacer, VStack } from '@chakra-ui/react'
import { createCoupons, deleteCouponsById, editCouponsById } from '../../apis/admin'
import { getURLQuery } from '../../helper/url'

const defaultValues = {
    code: '',
    name: '',
    store: '',
    user: [],
    discount: '',
    start_date: new Date(),
    expiry: '',
    active: 'true',
    public: 'true',
    paid: 'false',
    price: '',
    type: 'per',
    total_redeems: '',
    max_redeems: '100',
    terms: 'The Coupons can only be redeemed in-person at physical store location on the coupon.,The coupon is valid for a specific period, and redemption must occur within this timeframe.,This Coupon will be applicable above the sales and bargaining price.',
    lottery: 'false',
    constraints: {
        min_lottery_discount: '',
        max_lottery_discount: '',
        min_amount: '',
        max_discount: '',
        max_amount: 0,
        max_items: 0,
        min_items: 0,
        min_lottery_amount: 0,
        max_lottery_amount: 0,
        styles: [],
    },
}


const CreateCoupons = ({ data }) => {
    const navigate = useNavigate()

    const form = useForm({ defaultValues: data || defaultValues })
    const value = form.watch()


    const { search } = useLocation()
    const _params = getURLQuery(search)
    const { store_id, store_name } = _params || {}

    const { submit, isLoading } = useSubmit({
        service: data?._id ? editCouponsById : createCoupons,
        id: data?._id,
        onSuccess: () => navigate('/admin/coupons'),
    })

    const Delete = useSubmit({
        service: deleteCouponsById,
        id: data?._id,
        onSuccess: () => navigate(-1),
        alert: true,
    })

    const save = () => {
        const state = { ...form.getValues() }
        submit({
            ...state,
            store: store_id ? [store_id] : [state.store.value],
            app_event: state?.app_event?.map((event) => event.value),
            products: state?.products?.map((product) => product.value),
            terms: state.terms.replace('(Auto Applied)', ''),
            constraints: {
                ...state.constraints,
                styles: state.constraints.styles.map((style) => style.value),
            },
        })
    }

    return (
        <VStack alignItems={'flex-start'} w="100%">
            {!data && <CompHeader title={'Create Coupons'} />}

            <CouponForm form={form} data={data} store_id={store_id} store_name={store_name} />

            <HStack alignItems={'center'} w="100%">
                <Button m='auto'
                    colorScheme={data ? 'green' : 'blue'}
                    variant={'solid'}
                    onClick={save}
                    isLoading={isLoading}
                    size='sm'
                >{data ? 'Update Coupon' : "Add Coupon"}</Button>
                <Spacer />
                {data && <Button m='auto'
                    variant={'solid'}
                    color='red'
                    isLoading={Delete.isLoading}
                    onClick={Delete.submit} size='sm' >
                    Delete Coupon
                </Button>}
            </HStack>
        </VStack>
    )
}

export default CreateCoupons