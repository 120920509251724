import axios from "axios";
import { baseurl } from "./url";
const API = axios.create({ baseURL: `${baseurl}/admin` });

API.interceptors.request.use((req) => {
    if (localStorage.getItem("token")) {
        req.headers.Authorization = localStorage.getItem("token");
    }
    return req;
});

// app_events
export const getAppEvents = ({ params }) => API.get("/app_events", { params })

// restaurants
export const getRestaurants = ({ params }) => API.get("/restaurants", { params });
export const getRestaurantById = ({ id }) => API.get(`/restaurants/${id}`);
export const editRestaurantById = ({ id, data }) => API.put(`/restaurants/${id}`, data);
export const deleteRestaurantById = ({ id }) => API.delete(`/restaurants/${id}`);
export const createRestaurant = ({ data }) => API.post("/restaurants", data);

// food Coupon 
export const getRedeemCouponReward = ({ params }) => API.get("/food_coupons/redeem-coupon-reward", { params });
export const getFoodCoupons = ({ params }) => API.get("/food_coupons", { params });
export const getFoodCouponsById = ({ id }) => API.get(`/food_coupons/${id}`);
export const editFoodCouponsById = ({ id, data }) => API.put(`/food_coupons/${id}`, data);
export const deleteFoodCouponsById = ({ id }) => API.delete(`/food_coupons/${id}`);
export const createFoodCoupons = ({ data }) => API.post("/food_coupons", data);


// otps
export const getSesssionOtps = ({ params }) => API.get("/otps/session_otps", { params })

// user
export const getusers = ({ params }) => API.get("/user", { params })
export const getuserbyid = ({ id }) => API.get(`/user/${id}`)
export const getuserJourney = ({ id }) => API.get(`/user/journey/${id}`)
export const edituserbyid = ({ id, data }) => API.put(`/user/${id}`, data)
export const createuser = ({ data }) => API.post("/user", data)
export const deleteuser = ({ id }) => API.delete(`/user/${id}`)

// 
export const markUserAsDone = ({ data }) => API.put(`/user/call_record/make_user_done`, data);
export const getMyUserCallingBatches = ({ params }) => API.get(`/user/call_record/batches/mod_batches`, { params });
export const getAllUserCallingData = ({ params }) => API.get(`/user/call_record/all`, { params });
export const createBatches = ({ params }) => API.post(`/user/call_record/create_batch`, { params });
export const getAllUserbatches = ({ params }) => API.get(`/user/call_record/batches`, { params });
export const addUserCallRecord = ({ data }) => API.post(`/user/call_record`, data);
export const acquireBatch = ({ data }) => API.post(`/user/call_record/acquire_batch`, data);
export const getUserCallRecord = ({ id, data }) => API.get(`/user/call_record/${id}`, data);

// subscriptions
export const getUserSubscriptions = () => API.get("/subscriptions/user");
export const getUserSubscriptionsById = ({ id }) => API.get(`/subscriptions/user/${id}`);
export const getUserSubscriptionUsers = ({ id }) => API.get(`/subscriptions/user/users/${id}`);
export const editUserSubscriptionsById = ({ id, data }) => API.put(`/subscriptions/user/${id}`, data);
export const createUserSubscriptions = ({ data }) => API.post("/subscriptions/user", data);
export const deleteUserSubscriptionsById = ({ id }) => API.delete(`/subscriptions/user/${id}`);

// store subscriptions
export const getStoreSubscriptions = () => API.get("/subscriptions/store");
export const getStoreSubscriptionsById = ({ id }) => API.get(`/subscriptions/store/${id}`);
export const getStoreSubscriptionUsers = ({ id }) => API.get(`/subscriptions/store/users/${id}`);
export const editStoreSubscriptionsById = ({ id, data }) => API.put(`/subscriptions/store/${id}`, data);
export const createStoreSubscriptions = ({ data }) => API.post("/subscriptions/store", data);
export const deleteStoreSubscriptionsById = ({ id }) => API.delete(`/subscriptions/store/${id}`);

// chain
export const getchains = ({ params }) => API.get("/chain", { params });
export const getchainsbyid = ({ id }) => API.get(`/chain/${id}`);
export const editchainsbyid = ({ id, data }) => API.put(`/chain/${id}`, data);
export const createchains = ({ data }) => API.post("/chain", data);
export const deleteChain = ({ id, data }) => API.delete(`/chain/${id}`, data);
export const verifyChain = ({ id, data }) => API.put(`/chain/verify/${id}`, data);

// brands
export const getunverifiedbrands = () => API.get("/brand?verified=false");
export const getverifiedbrands = () => API.get("/brand?verified=true");
export const getbrands = ({ params }) => API.get("/brand", { params })
export const getbrandsbyid = ({ id }) => API.get(`/brand/${id}`);
export const editbrandsbyid = ({ id, data }) => API.put(`/brand/${id}`, data);
export const createbrands = ({ data }) => API.post("/brand", data);
export const deleteBrand = ({ id }) => API.delete(`/brand/${id}`);
export const verifyBrand = ({ id }) => API.put(`/brand/verify/${id}`);

// link_shortener
export const getLinkShortener = ({ params }) => API.get("/link_shortener", { params });
export const getLinkShortenerByCity = ({ params, id }) => API.get(`/link_shortener/${id}`, { params });
export const getLinkShortenerbyid = ({ id }) => API.get(`/link_shortener/single/${id}`);
export const editLinkShortenerbyid = ({ id, data }) => API.put(`/link_shortener/${id}`, data);
export const createLinkShortener = ({ data }) => API.post("/link_shortener", data);
export const deleteLinkShortenerById = ({ id }) => API.delete(`/link_shortener/${id}`);

// malls
export const getmalls = ({ params }) => API.get("/mall", { params });
export const getmallsByCity = ({ params, id }) => API.get(`/mall/${id}`, { params });
export const getmallsbyid = ({ id }) => API.get(`/mall/single/${id}`);
export const editmallsbyid = ({ id, data }) => API.put(`/mall/${id}`, data);
export const createmalls = ({ data }) => API.post("/mall", data);
export const deleteMall = ({ id }) => API.delete(`/mall/${id}`);
export const verifyMall = ({ id }) => API.put(`/mall/verify/${id}`);

// mod
export const getAvaEnq = ({ params }) => API.get("/ava_enq", { params })
export const getAvaEnqById = ({ id, params }) => API.get(`/ava_enq/${id}`, { params })

// mod
export const getmod = () => API.get("/mod")
export const getProfile = () => API.get("/mod/getProfile")
export const getmodbyid = ({ id }) => API.get(`/mod/${id}`);
export const editmodbyid = ({ id, data }) => API.put(`/mod/${id}`, data);
export const createmod = ({ data }) => API.post("/mod", data);
export const deletemod = ({ id }) => API.delete(`/mod/${id}`);
export const getModCalls = ({ id, params }) => API.get(`/mod/calls/${id}`, { params });

// payments
export const getpayments = ({ params }) => API.get("/payments", { params });
export const getpaymentbyid = ({ id }) => API.get(`/payments/${id}`);

// offer template
export const getoffertemplate = () => API.get("/offer-template");
export const getoffertemplatebyid = ({ id }) => API.get(`/offer-template/${id}`);
export const editoffertemplatebyid = ({ id, data }) => API.put(`/offer-template/${id}`, data);
export const createoffertemplate = ({ data }) => API.post("/offer-template", data);
export const deleteoffertemplatebyid = ({ id }) => API.delete(`/offer-template/${id}`);

//gender
export const getgender = () => API.get("/gender");
export const getgenderbyid = ({ id }) => API.get(`/gender/${id}`);
export const editgenderbyid = ({ id, data }) => API.put(`/gender/${id}`, data);
export const creategender = ({ data }) => API.post("/gender", data);
export const deletegender = ({ id }) => API.delete(`/gender/${id}`)

// notification 
export const getnotification = () => API.get("/notification");
export const getnotificationbyid = ({ id }) => API.get(`/notification/${id}`);
export const createnotification = ({ data }) => API.post("/notification", data);

// services
export const getservices = () => API.get("/services");
export const getServicesByBrand = ({ store_id, brand_id }) => API.get(`/services/${store_id}/brand/${brand_id}`);
export const updateServicesByBrand = ({ store_id, brand_id, data }) => API.put(`/services/${store_id}/brand/${brand_id}`, data);

// wear
export const getwears = ({ params }) => API.get("/wear", { params });
export const getwearbyid = ({ id }) => API.get(`/wear/${id}`);
export const editwearbyid = ({ id, data }) => API.put(`/wear/${id}`, data);
export const deletewearbyid = ({ id }) => API.delete(`/wear/${id}`,)
export const createwear = ({ data }) => API.post("/wear", data);
export const getWearProducts = ({ gender }) => API.get(`/wear/register/products/${gender}`)
export const getAllWearProducts = () => API.get(`/wear/products`)

// getAllStoreAuth
export const getAllStoreAuth = ({ params }) => API.get("/store/auth", { params });
export const getStoreAuthById = ({ id }) => API.get(`/store/auth/${id}`);

// clothingTypes
export const getClothingTypes = () => API.get("/clothing_type");
export const createclothingTypes = ({ data }) => API.post("/clothing_type", data);
export const getClothingTypesByGenders = ({ id }) => API.get(`/clothing_type/${id}`);

// products
export const getProducts = ({ params }) => API.get("/products", { params });
export const editProductById = ({ id, data }) => API.put(`/products/${id}`, data);
export const deleteProductById = ({ id, data }) => API.delete(`/products/${id}`, data);
export const createProduct = ({ data }) => API.post(`/products`, data);
export const getProductById = ({ id }) => API.get(`/products/${id}`);
export const getProductTree = ({ id }) => API.get(`/products/tree/${id}`);

// products wears
export const getProductsWearsById = ({ id, params }) => API.get(`/products/wears/${id}`, { params });
export const editProductsWearsById = ({ id, data }) => API.post(`/products/wears/${id}`, data);
export const toggleProductsWearsById = ({ id, data }) => API.post(`/products/wears/toggle/${id}`, data);

// product filters
export const getProductFiltersById = ({ id, params }) => API.get(`/products/filters/${id}`, { params });
export const getPopulatedProductFiltersById = ({ id, params }) => API.get(`/products/filters/populate/${id}`, { params });
export const editProductFiltersById = ({ id, data }) => API.post(`/products/filters/${id}`, data);
export const toggleFilterRequired = ({ id, filter_id, gender }) => API.post(`/products/${id}/filter/${filter_id}/gender/${gender}`);
export const toggleFilterRequiredInUpload = ({ id, filter_id, gender }) => API.post(`/products/upload/${id}/filter/${filter_id}/gender/${gender}`);
export const editProductThumbnail = ({ id, data }) => API.put(`/products/thumbnails/${id}`, data);

// parametric
export const createParametric = ({ data }) => API.post("/parametric", data);
export const deleteParametric = ({ id }) => API.delete(`/parametric/${id}`);
export const getParametric = () => API.get("/parametric");
export const getParametricByProduct = ({ id }) => API.get(`/parametric/product/${id}`);
export const getParametricById = ({ id }) => API.get(`/parametric/${id}`);
export const editParametricById = ({ id, data }) => API.put(`/parametric/${id}`, data);

//titleKeys
export const getTitleKeys = ({ params }) => API.get("/titleKey", { params });
export const editKeyRules = ({ type, type_id, data }) => API.put(`/titleKey/rules/${type}/${type_id}`, data);
export const getTitleKeysById = ({ id, params }) => API.get(`/titleKey/${id}`, { params });
export const getKeysById = ({ id, params }) => API.get(`/titleKey/key/${id}`, { params });
export const editTitleKeysById = ({ id, data }) => API.put(`/titleKey/${id}`, data);
export const deleteTitleKeysById = ({ id }) => API.delete(`/titleKey/${id}`);
export const addTitleKeys = ({ data }) => API.post("/titleKey", data);

//Offers
export const getOffers = ({ params }) => API.get("/offers", { params });
export const getOffersById = ({ id }) => API.get(`/offers/${id}`);
export const updateMultipleOffers = ({ data }) => API.post(`/offers/update_multiple`, data);
export const editOffersById = ({ id, data }) => API.put(`/offers/${id}`, data);
export const deleteOffersById = ({ id }) => API.delete(`/offers/${id}`);
export const addOffers = ({ data }) => API.post("/offers", data);


//adminOffers
export const addAdminOffers = ({ data }) => API.post("/adminOffers", data);
export const getStoreList = ({ data }) => API.post("/adminOffers/getStoreList", data);
export const getCreatedAdminOffers = ({ params }) => API.get("/adminOffers/created", { params });
export const getCreatedAdminOffersByID = ({ params, id }) => API.get(`/adminOffers/created/${id}`, { params });
export const deleteCreatedAdminOffersByID = ({ params, id }) => API.delete(`/adminOffers/created/${id}`, { params });
export const getSuggestedAdminOffers = ({ params }) => API.get("/adminOffers/suggested", { params });
export const getSuggestedAdminOffersByID = ({ params, id }) => API.get(`/adminOffers/suggested/${id}`, { params });
// export const getAdminOffersById = ({ id }) => API.get(`/adminOffers/${id}`);
// export const editAdminOffersById = ({ id, data }) => API.put(`/adminOffers/${id}`, data);
// export const deleteAdminOffersById = ({ id }) => API.delete(`/adminOffers/${id}`);

//calls

export const getCalls = ({ params }) => API.get("/telecmi/virtual_calls/get_calls", { params });
export const getCallsById = ({ id }) => API.get(`/telecmi/virtual_calls/calls/${id}`);


//OfferTemplateList
export const getOfferTemplateList = () => API.get("/offerTemplateList");
export const getOfferTemplateListById = ({ id }) => API.get(`/offerTemplateList/${id}`);
export const editOfferTemplateListById = ({ id, data }) => API.put(`/offerTemplateList/${id}`, data);
export const deleteOfferTemplateListById = ({ id }) => API.delete(`/offerTemplateList/${id}`);
export const addOfferTemplateList = ({ data }) => API.post("/offerTemplateList", data);

//subscriptions
export const getSubscriptions = () => API.get("/subscriptions");
export const getSubscriptionsById = ({ id }) => API.get(`/subscriptions/${id}`);
export const editSubscriptionsById = ({ id, data }) => API.put(`/subscriptions/${id}`, data);
// export const deleteSubscriptionsById = ({ id }) => API.delete(`/subscriptions/${id}`);
export const addSubscriptions = ({ data }) => API.post("/subscriptions", data);

// globals
export const getglobalvars = () => API.get("/global/vars");
export const editglobalvars = ({ data }) => API.put("/global/vars", data);
export const updateGlobalVariableList = ({ data }) => API.put("/global/vars/update", data);
export const getglobalvarsByType = ({ id, params }) => API.get(`/global/vars/${id}`, { params });

// CBS
export const getCBSEquiries = ({ params }) => API.get("/cbs", { params });
export const getCBSEquiriesById = ({ id }) => API.get(`/cbs/${id}`);
export const editCBSEquiriesById = ({ id, data }) => API.put(`/cbs/${id}`, data);
export const deleteCBSEquiriesById = ({ id }) => API.delete(`/cbs/${id}`);

// search
export const globalSearch = ({ params }) => API.get("/search", { params });
export const allSuggestions = ({ params }) => API.get('/search/suggestions', { params })

//  flags
export const getFlags = ({ params }) => API.get("/flags", { params });
export const getFlagsById = ({ id }) => API.get(`/flags/${id}`);
export const editFlagsById = ({ id, data }) => API.put(`/flags/${id}`, data);

// FlagsReasons
export const createFlagReason = ({ data }) => API.post("/flags/reasons", data);
export const getFlagReasons = () => API.get("/flags/reasons");
export const getFlagReasonsById = ({ id }) => API.get(`/flags/reasons/${id}`);
export const editFlagReasonsById = ({ id, data }) => API.put(`/flags/reasons/${id}`, data);
export const deleteFlagReasonsById = ({ id }) => API.delete(`/flags/reasons/${id}`);


// reg sessions
export const getStoreChangesHistory = ({ id }) => API.get(`/reg_sessions/history/${id}`);


// bot activities
export const getBotActivities = ({ params }) => API.get("/bot_activities", { params });

// ads
export const createAds = ({ data }) => API.post("/ads", data);
export const getAds = ({ params }) => API.get("/ads", { params });
export const getAdsById = ({ id }) => API.get(`/ads/${id}`);
export const editAdsById = ({ id, data }) => API.put(`/ads/${id}`, data);
export const deleteAdsById = ({ id }) => API.delete(`/ads/${id}`);

//super actions
export const performSupperActions = ({ data }) => API.post("/superactions", data);


// analytics
export const getAnalytics = ({ params }) => API.get("/analytics", { params });
export const getUserAllAnalytics = ({ params }) => API.get("/analytics/user/all", { params });
export const getUserSource = ({ params }) => API.get("/analytics/user/src", { params });
export const getUserSearch = ({ params }) => API.get("/analytics/user/user_search", { params });
export const getUserReviewAnalysics = ({ params }) => API.get("/analytics/user/call_reviews", { params });

//onboarder
export const createOnboader = ({ data }) => API.post("/onboarders", data);
export const getOnboarders = ({ params }) => API.get("/onboarders", { params });
export const getOnboardersById = ({ id }) => API.get(`/onboarders/${id}`);
export const editOnboardersById = ({ id, data }) => API.put(`/onboarders/${id}`, data);
export const deleteOnboardersById = ({ id }) => API.delete(`/onboarders/${id}`);
export const getOnboardersReports = ({ params, id }) => API.get(`onboarders/reports/${id}`, { params });
export const getOnboardersStats = ({ params, id }) => API.get(`onboarders/stats/${id}`, { params });

// lateststyles
// export const createLatestStyles = ({ data }) => API.post("/lateststyles", data);
export const getLatestStyles = ({ params }) => API.get("/lateststyles", { params });
export const getLatestStylesById = ({ id }) => API.get(`/lateststyles/${id}`);
export const editLatestStylesById = ({ id, data }) => API.put(`/lateststyles/${id}`, data);
export const deleteLatestStylesById = ({ id }) => API.delete(`/lateststyles/${id}`);
export const createNewProductFromExisting = ({ data }) => API.post("/lateststyles/createNewProductFromExisting", data);

// locations
export const getLocations = ({ params }) => API.get("/location", { params });
export const addNewCity = ({ params, data }) => API.post("/location/add_new_city", data, { params });
export const toggleLocationStatus = ({ data }) => API.put(`/location/status`, data);

// market
export const getMarkets = ({ params }) => API.get("/markets", { params });
export const getMarketById = ({ id }) => API.get(`/markets/${id}`);
export const editMarketById = ({ id, data }) => API.put(`/markets/${id}`, data);
export const deleteMarketById = ({ id }) => API.delete(`/markets/${id}`);
export const createMarket = ({ data }) => API.post("/markets", data);
export const getMarketsByCity = ({ id }) => API.get(`/markets/city/${id}`);

// market areas
export const createMarketArea = ({ market_id, data }) => API.post(`/markets/${market_id}/areas`, data);
export const getMarketAreas = ({ market_id, id, params }) => API.get(`/markets/${market_id}/areas`, { params });
export const getMarketAreasById = ({ market_id, id }) => API.get(`/markets/${market_id}/areas/${id}`);
export const editMarketAreasById = ({ market_id, id, data }) => API.put(`/markets/${market_id}/areas/${id}`, data);
export const deleteMarketAreasById = ({ market_id, id }) => API.delete(`/markets/${market_id}/areas/${id}`);

// qr 
export const getQR = ({ params }) => API.get("/qr", { params });
export const getQRById = ({ id }) => API.get(`/qr/${id}`);
export const resetQRById = ({ id }) => API.put(`/qr/reset/${id}`);
export const editQRById = ({ id, data }) => API.put(`/qr/${id}`, data);
export const deleteQRById = ({ id }) => API.delete(`/qr/${id}`);
export const createQR = ({ data }) => API.post("/qr", data);
export const createMultiQR = () => API.post("/qr/multi/10",);

// app versions
export const getAppVersions = ({ params }) => API.get("/app_versions", { params });
export const getAppVersionsById = ({ id }) => API.get(`/app_versions/${id}`);
export const editAppVersionsById = ({ id, data }) => API.put(`/app_versions/${id}`, data);
export const deleteAppVersionsById = ({ id }) => API.delete(`/app_versions/${id}`);
export const createAppVersions = ({ data }) => API.post("/app_versions", data);

// app updates
export const getRealtimeAppUpdates = () => API.get("/app_updates");


// adv
export const getStoreWithNoOffers = ({ params }) => API.get("/adv/get_store_with_no_offers", { params })
export const getStoreWithAdvanceFilters = ({ params }) => API.get("/adv/get_store_with_advance_filters", { params })
export const getDuplicateStores = ({ params }) => API.get("/adv/get_duplicate_stores", { params })


// meetings 
export const getMeetings = ({ params }) => API.get("/meetings", { params });
export const getMeetingsById = ({ id }) => API.get(`/meetings/${id}`);
export const editMeetingsById = ({ id, data }) => API.put(`/meetings/${id}`, data);
export const deleteMeetingsById = ({ id }) => API.delete(`/meetings/${id}`);
export const createMeetings = ({ data }) => API.post("/meetings", data);

// Stories
export const getStories = ({ params }) => API.get("/stories", { params });
export const getStoriesById = ({ id }) => API.get(`/stories/${id}`);
export const postSponsoredStory = ({ data }) => API.post("/stories/sponsored", data);
export const editSponsoredStory = ({ data, id }) => API.put(`/stories/sponsored/${id}`, data);
export const deleteSponsoredStory = ({ id }) => API.delete(`/stories/sponsored/${id}`);
export const getSponsoredStories = ({ params }) => API.get("/stories/sponsored", { params });

// kpi
export const getAllKPI = ({ params }) => API.get("/kpi/all", { params });
export const getBasicKPI = ({ params }) => API.get("/kpi/basic", { params });
export const getViewWiseStores = ({ params }) => API.get("/kpi/viewWiseStores", { params });


// conn
export const getConnections = ({ params }) => API.get("/conn", { params });
export const getCurrentStats = ({ params }) => API.get("/conn/current", { params });


// api calls 
export const getApiCalls = ({ params }) => API.get("/api_calls", { params });

// coupons
export const getCoupons = ({ params }) => API.get("/coupons", { params });
export const getUserCoupons = ({ params, id }) => API.get(`/coupons/user/${id}`, { params });
export const getCouponsById = ({ id }) => API.get(`/coupons/${id}`);
export const editCouponsById = ({ id, data }) => API.put(`/coupons/${id}`, data);
export const deleteCouponsById = ({ id }) => API.delete(`/coupons/${id}`);
export const createCoupons = ({ data }) => API.post("/coupons", data);
export const assignCouponsToUser = ({ data }) => API.post(`/coupons/assign`, data);
export const getAllCouponData = ({ params, id }) => API.get(`/coupons/all_coupon_data/${id}`, { params });
export const getGrabbedCoupons = ({ params }) => API.get("/coupons/grabbed_coupons", { params });

// wa_services
export const sendIntroMessageToUser = ({ data }) => API.post("/wa_services/sendIntroMessageToUser", data);
export const sendOffersToStores = ({ data }) => API.post("/wa_services/sendOffersToStores", data);
export const getWAMessageLogs = ({ params }) => API.get("/wa_services/logs", { params });
export const getWaMessageActions = ({ params }) => API.get("/wa_services/wa_message_actions", { params });
export const sendBannerToPhoneNumber = ({ data }) => API.post("/wa_services/send_banner_to_phone_number", data);

//store_dump
export const getStoreDump = ({ params }) => API.get("/store_dump", { params });
export const recoverStore = ({ id }) => API.put(`/store_dump/recover/${id}`);

// ref links
export const getRefLinks = ({ params }) => API.get("/ref_links", { params });
export const getRefLinksById = ({ id }) => API.get(`/ref_links/${id}`);
export const editRefLinksById = ({ id, data }) => API.put(`/ref_links/${id}`, data);
export const deleteRefLinksById = ({ id }) => API.delete(`/ref_links/${id}`);
export const createRefLinks = ({ data }) => API.post("/ref_links", data);

// upload 
export const uploadImage = ({ data }) => API.post("/upload/image", data);
export const uploadVideo = ({ data }) => API.post("/upload/video", data);

// app_reviews 
export const appReviews = ({ params }) => API.get("/app_reviews", { params });

// admin Alerts 
export const getAdminAlertUsers = ({ params }) => API.get("/admin_alerts/users", { params });
export const enrollUserInAlert = ({ data }) => API.post("/admin_alerts/enroll_user", data);
export const removeAdminAlertUser = ({ data }) => API.post("/admin_alerts/remove_user", data);