import React from 'react'
import { colors } from '../../theme/colors'
import useSubmit from '../../hook/useSubmit'
import { addNewCity } from '../../apis/admin'
import { Button, HStack, Input, Text } from '@chakra-ui/react'

const AddCity = ({
    refetch,
    stateId,
}) => {
    const [value, setValue] = React.useState('')

    const { submit, isLoading } = useSubmit({
        service: addNewCity,
        onSuccess: () => { refetch() }
    })

    return (
        <HStack w={'100%'} p={4} borderBottomWidth={1} >
            <Text fontSize={'md'} fontWeight={'bold'} w={'20'} >
                Add City
            </Text>
            <Input variant={"outline"} size="lg" placeholder="Enter City Name"
                bg={colors.inputBg} value={value}
                onChange={(e) => setValue(e.target.value)}
            />
            <Button disabled={isLoading} isLoading={isLoading}
                onClick={() => submit({ state_id: stateId, city_name: value })}
            >
                Add City
            </Button>
        </HStack>
    )
}

export default AddCity